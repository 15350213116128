<template>
  <div :class="[sideNavToggle.searchOpen?'opened-search':'default-search']" id="search-sidebar">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'shop-now' }">ShopNowPH</router-link>
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-2">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6"><h3>Search</h3></div>
        <div class="col-3">
          <button class="btn close-side-menu" @click="[sideNavToggle.toggleSearchOpen()]">CLOSE</button>
        </div>
      </div>
      <div class="ps-form--quick-search">
        <div class="form-group--icon" id="select_container">
          <i class="icon-chevron-down"></i>
          <select
            v-model="searchMethod"
            class="form-control"
            style="width: 100%; "
          >
            <option class="level-0" value="Product">Product Name</option>
            <option class="level-0" value="Shop">Shop Name</option>
            <option class="level-0" value="Shop location">Locations</option>
          </select>
        </div>
        <!-- <GmapAutocomplete
              class="form-control"
              v-if="search_method='Shop location'"
              @place_changed="setPlace"
            /> -->
        <input
          class="form-control"
          type="text"
          v-model="searchQuery"
          v-if="searchMethod === 'Shop'"
          placeholder="What store are your looking for ?"
          id="sidebar_input_search_shop"
        />
        <input
          class="form-control"
          type="text"
          v-model="searchQuery"
          v-if="searchMethod === 'Shop location'"
          placeholder="Recipient town or city?"
          id="sidebar_input_search_shop_location"
        />
        <input
          class="form-control"
          type="text"
          v-model="searchQuery"
          v-if="searchMethod === 'Product'"
          placeholder="What product are your looking for?"
          id="sidebar_input_search_product"
        />
        <button @click.prevent="search()" id="side_search_button">
          Search
        </button>
      </div>
    </div>
    <div class="navigation__content"></div>
  </div>
</template>

<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
</script>


<script>
import { mapState } from "vuex";

import CartMini from "@/components/CartMini.vue";
export default {
  data() {
    return {
      searchMethod: "Product",
      searchQuery: "",
      currentPlace: {},
    };
  },
  components: {
    CartMini,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  methods: {
    search() {
      if (!this.searchQuery) {
        alert("Please enter a search parameter");
      } else if (this.searchMethod === "Shop location") {
        location.replace(`/search-shops-result/${this.searchQuery}/location`);
      } else if (this.searchMethod === "Shop category") {
        location.replace(`/search-shops-result/${this.searchQuery}/category`);
      } else if (this.searchMethod === "Shop") {
        location.replace(`/search-shops-result/${this.searchQuery}/shop`);
      } else {
        this.$router.push({
          name: "products_result",
          params: { query: this.searchQuery },
        });

        location.replace(`/search-products-result/${this.searchQuery}`);
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
  },
};
</script>

<style>
.default-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
