<template>
  <section>
    <Header v-if="showTopNav"></Header>
    <MiddleNav v-if="showTopNav"></MiddleNav>
    


    <CartMobile></CartMobile>
    <NavigationMobile></NavigationMobile>
    <NavigationList ></NavigationList>
    <SearchSideBar></SearchSideBar>
    <MenuMobile></MenuMobile>
    <ProductsMobile></ProductsMobile>
    <SupportMobile></SupportMobile>
    <router-view />
    <FooterNav></FooterNav>
  </section>
</template>


<script>
import SupportMobile from "../../../components/SupportMobile";
import MenuMobile from "../../../components/MenuMobile";
import MiddleNav from "../../../components/Navs/MiddleNav";

import CartMobile from "../../../components/CartMobile";
import NavigationMobile from "../../../components/NavigationMobile";
import NavigationList from "../../../components/NavigationList";
import SearchSideBar from "../../../components/SearchSideBar";
import Header from "../../../components/Navs/Header";
import FooterNav from "../../../components/Navs/FooterNav";
import ProductsMobile from "../../../components/ProductsMobile.vue";

export default {
  name: "PublicBase",
  components: {
    FooterNav,
    Header,
    SearchSideBar,
    NavigationList,
    NavigationMobile,
    CartMobile,
    MiddleNav,
    MenuMobile,
    ProductsMobile,
    SupportMobile,
  },
  
  computed: {
    showTopNav() {
      if (
        ["register", "login", "auth", "verify", "forgot_password"].indexOf(
          this.$route.name
        ) > -1
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  
};
</script>


<style scoped></style>
