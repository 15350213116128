<template>
  <div :class="[sideNavToggle.supportOpen?'opened-support':'default-support']" id="support-mobile">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'shop-now' }">ShopNowPH</router-link>
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-2">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <h3>Support</h3>
        </div>
        <div class="col-3">
          <button class="btn close-side-menu" @click="[sideNavToggle.toggleSupportOpen()]">CLOSE</button>
        </div>
      </div>
    </div>
    <div class="ps-panel__content">
      <div></div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {},
};
</script>

<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
</script>

<style>
.default-support {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-support {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
