<template>
  <div :class="[sideNavToggle.menuOpen?'opened-menu':'default-menu']" id="menu-mobile">
    <div class="ps-panel__header" style="background-color: #000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'shop-now' }">ShopNowPH</router-link>
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-2">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <h3>Menu</h3>
        </div>
        <div class="col-3">
          <button class="btn close-side-menu" @click="[sideNavToggle.toggleMenuOpen()]">CLOSE</button>
        </div>
      </div>
    </div>
    <div class="ps-panel__content">
      <ul class="menu--mobile">
        <li class=""><a href="/">Home</a></li>
        <li>
          <router-link :to="{ name: 'vouchers' }">BuyVoucherPH</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'topup_now' }">SmartTopUpPH</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'runner_requests' }"
            >GetERunnerPH</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'prescriptions' }"
            >SendPrescriptionPH</router-link
          >
        </li>

        <li class="menu-item">
          <router-link :to="{ name: 'listings' }">ClassifiedListPH</router-link>
        </li>

        <li>
          <router-link :to="{ name: 'request_quotation' }"
            >RequestQuotationPH</router-link
          >
        </li>
        <li v-if="auth.isAuthenticated" style="display: none">
          <router-link :to="{ name: 'prescriptions' }"
            >myPrescriptions</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'merchant_register' }"
            >Become a Merchant</router-link
          >
        </li>
        <li v-if="auth.isAuthenticated">
          <router-link :to="{ name: 'my_profile' }">{{
            auth.user.name
          }}</router-link>
        </li>
        <li v-else>
          <router-link :to="{ name: 'login' }">Login</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'cart' }">Cart</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'cart' }">Checkout</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
//import { menuOpen } from "./Navs/mobileNavs";
//import { toggle } from "./Navs/mobileNavs";
</script>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      searchMethod: "Product",
      searchQuery: "",
      currentPlace: {},
    };
  },
  
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
    
  },
  methods: {
    search() {
      if (this.searchMethod === "Shop location") {
        location.replace(`/search-shops-result/${this.searchQuery}/location`);
      } else if (this.searchMethod === "Shop category") {
        location.replace(`/search-shops-result/${this.searchQuery}/category`);
      } else if (this.searchMethod === "Shop") {
        location.replace(`/search-shops-result/${this.searchQuery}/shop`);
      } else {
        this.$router.push({
          name: "products_result",
          params: { query: this.searchQuery },
        });

        location.replace(`/search-products-result/${this.searchQuery}`);
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "home" });
    },
    handleClick() {
      this.$emit('change-style');
    }
  },
};
</script>

<style>
.default-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
